import React, { useEffect, useState } from "react";
import NftLoot from "../../abis/RichApeCarClub.json";
import Web3 from "web3";
import { getErrorNotificationMessage } from "../Helper/NotificationMessage";
import { createNotification } from "react-redux-notify";
import { connect } from "react-redux";
import configuration from "react-global-configuration";
import { Link } from "react-router-dom";

const ConfigIndex = (props) => {
  useEffect(() => { }, []);

  const [nftLoot, setNftLoot] = useState(null);
  const [connectMetaMask, setConnectMetaMask] = useState(false);
  const [account, setAccount] = useState("");
  const [ethBalance, setEthBalance] = useState("");
  const [currentLimit, setCurrentLimit] = useState("");
  const [totalSupply, setTotalSupply] = useState("");
  const [limit, setLimit] = useState("");
  const [buttonContent, setButtonContent] = useState("");
  const [minterAddress, setMinterAddress] = useState("");
  const [burnerAddress, setBurnerAddress] = useState("");
  const [revokeMinterAddress, setRevokeMinterAddress] = useState("");
  const [revokeBurnerAddress, setRevokeBurnerAddress] = useState("");
  const [publicMintStatus, setPublicMintStatus] = useState(true);
  const [walletRestrictStatus, setWalletRestrictStatus] = useState(true);
  const [walletCount, setWalletCount] = useState(3);
  const [relLimit, setRelLimit] = useState(1000);
  const [mintFee, setMintFee] = useState("0.15");

  const connectingMetaMask = async () => {
    if (window.ethereum) {
      window.web3 = new Web3(window.ethereum);
      await window.ethereum.enable();
      console.log("Etherum enabled");
      setConnectMetaMask(true);
      getWalletAddress();
      return true;
    } else if (window.web3) {
      window.web3 = new Web3(window.web3.currentProvider);
      setConnectMetaMask(true);
      getWalletAddress();
      return true;
    } else {
      window.alert(
        "Non-Ethereum browser detected. You should consider trying MetaMask!"
      );
      return false;
    }

  };

  const getWalletAddress = async () => {
    const web3 = window.web3;
    try {
      // Network ID
      const networkId = await web3.eth.net.getId();
      const networkData = NftLoot.networks[networkId];
      if (networkData) {
        const nftLoot = new web3.eth.Contract(NftLoot.abi, networkData.address);
        // const nftLoot = new web3.eth.Contract(
        //   NftLoot.abi,
        //   "0x5c53faf70fbe9d32ba5da9cff5e3d5e851c26877"
        // );
        setNftLoot(nftLoot);
        const accounts = await web3.eth.getAccounts();
        setAccount(accounts[0]);
        const totalSupply = await nftLoot.methods.totalSupply().call();
        console.log("Total", totalSupply);
        const owner = await nftLoot.methods.owner().call();
        console.log("Owner", owner);

        const mintingFee = await nftLoot.methods.mintingFee().call();
        console.log("MintinFee", mintingFee);
        setMintFee(window.web3.utils.fromWei(mintingFee));

        const ethBalance = await web3.eth.getBalance(accounts[0]);
        setEthBalance(ethBalance);
        setConnectMetaMask(true);
      } else {
        window.alert("Contract not deployed to detected network.");
      }
    } catch (error) {
      console.log("Errror", error);
      window.alert("Error occuried, Refresh the page");
    }
  };

  const grandMintAccess = async (event) => {
    event.preventDefault();
    try {
      nftLoot.methods
        .grandMinterRole(minterAddress)
        .send({ from: account })
        .on("error", (error) => {
          let notificationMessageError;
          if (error.message == undefined) {
            notificationMessageError = "Please check the wallet address";
          } else {
            notificationMessageError = error.message
          }
          const notificationMessage = getErrorNotificationMessage(
            notificationMessageError
          );
          props.dispatch(createNotification(notificationMessage));
        })
        .on("receipt", (receipt) => {
          console.log("Trx", receipt.transactionHash);
        });

    } catch (error) {
      console.log("Errror", error);
      window.alert("Error occuried, Refresh the page");
    }
  };

  const grandBurnAccess = async (event) => {
    event.preventDefault();
    try {
      nftLoot.methods
        .grandBurnerRole(burnerAddress)
        .send({ from: account })
        .on("error", (error) => {
          let notificationMessageError;
          if (error.message == undefined) {
            notificationMessageError = "Please check the wallet address";
          } else {
            notificationMessageError = error.message
          }
          const notificationMessage = getErrorNotificationMessage(
            notificationMessageError
          );
          props.dispatch(createNotification(notificationMessage));
        })
        .on("receipt", (receipt) => {
          console.log("Trx", receipt.transactionHash);
        });
    } catch (error) {
      console.log("Errror", error);
      window.alert("Error occuried, Refresh the page");
    }
  };

  const revokeMintAccess = async (event) => {
    event.preventDefault();
    try {
      nftLoot.methods
        .revokeMinterAccess(revokeMinterAddress)
        .send({ from: account })
        .on("error", (error) => {
          let notificationMessageError;
          if (error.message == undefined) {
            notificationMessageError = "Please check the wallet address";
          } else {
            notificationMessageError = error.message
          }
          const notificationMessage = getErrorNotificationMessage(
            notificationMessageError
          );
          props.dispatch(createNotification(notificationMessage));
        })
        .on("receipt", (receipt) => {
          console.log("Trx", receipt.transactionHash);
        });
    } catch (error) {
      console.log("Errror", error);
      window.alert("Error occuried, Refresh the page");
    }
  };

  const revokeBurnAccess = async (event) => {
    event.preventDefault();
    try {
      nftLoot.methods
        .revokeBurnerAccess(revokeBurnerAddress)
        .send({ from: account })
        .on("error", (error) => {
          let notificationMessageError;
          if (error.message == undefined) {
            notificationMessageError = "Please check the wallet address";
          } else {
            notificationMessageError = error.message
          }
          const notificationMessage = getErrorNotificationMessage(
            notificationMessageError
          );
          props.dispatch(createNotification(notificationMessage));
        })
        .on("receipt", (receipt) => {
          console.log("Trx", receipt.transactionHash);
        });
    } catch (error) {
      console.log("Errror", error);
      window.alert("Error occuried, Refresh the page");
    }
  };

  const setPublicMintingStatus = async (event) => {
    event.preventDefault();
    try {
      nftLoot.methods.setPublicMint(publicMintStatus)
        .send({ from: account })
        .on("error", (error) => {
          let notificationMessageError;
          if (error.message == undefined) {
            notificationMessageError = "Something went wrong, Please try again.";
          } else {
            notificationMessageError = error.message
          }
          const notificationMessage = getErrorNotificationMessage(
            notificationMessageError
          );
          props.dispatch(createNotification(notificationMessage));
        })
        .on("receipt", (receipt) => {
          console.log("Trx", receipt.transactionHash);
        });
    } catch (error) {
      console.log("Errror", error);
      window.alert("Error occuried, Refresh the page");
    }
  }

  const setWalletRestriction = async (event) => {
    event.preventDefault();
    try {
      nftLoot.methods.setWalletRestriction(walletCount, walletRestrictStatus)
        .send({ from: account })
        .on("error", (error) => {
          let notificationMessageError;
          if (error.message == undefined) {
            notificationMessageError = "Something went wrong, Please try again.";
          } else {
            notificationMessageError = error.message
          }
          const notificationMessage = getErrorNotificationMessage(
            notificationMessageError
          );
          props.dispatch(createNotification(notificationMessage));
        })
        .on("receipt", (receipt) => {
          console.log("Trx", receipt.transactionHash);
        });
    } catch (error) {
      console.log("Errror", error);
      window.alert("Error occuried, Refresh the page");
    }
  }

  const setReleaseLimit = async (event) => {
    event.preventDefault();
    try {
      nftLoot.methods.setReleaseLimit(relLimit)
        .send({ from: account })
        .on("error", (error) => {
          let notificationMessageError;
          if (error.message == undefined) {
            notificationMessageError = "Something went wrong, Please try again.";
          } else {
            notificationMessageError = error.message
          }
          const notificationMessage = getErrorNotificationMessage(
            notificationMessageError
          );
          props.dispatch(createNotification(notificationMessage));
        })
        .on("receipt", (receipt) => {
          console.log("Trx", receipt.transactionHash);
        });
    } catch (error) {
      console.log("Errror", error);
      window.alert("Error occuried, Refresh the page");
    }
  }

  const setMintingFees = async (event) => {
    event.preventDefault();
    const web3 = window.web3;
    try {
      const mintFeeFormat = window.web3.utils.toWei(mintFee);
      nftLoot.methods.setMintingFee(mintFeeFormat)
        .send({ from: account })
        .on("error", (error) => {
          let notificationMessageError;
          if (error.message == undefined) {
            notificationMessageError = "Something went wrong, Please try again.";
          } else {
            notificationMessageError = error.message
          }
          const notificationMessage = getErrorNotificationMessage(
            notificationMessageError
          );
          props.dispatch(createNotification(notificationMessage));
        })
        .on("receipt", (receipt) => {
          console.log("Trx", receipt.transactionHash);
        });
    } catch (error) {
      console.log("Errror", error);
      window.alert("Error occuried, Refresh the page");
    }
  }



  return (
    <>
      <main className="h-full pb-16 overflow-y-auto content-wrapper">
        <div className="container  mt-0 mx-auto grid content-section">
          <h2 className="my-6 text-2xl font-semibold">
            BlockChain Configuration -{" "}
            <Link to="#" onClick={connectingMetaMask}>
              {connectMetaMask ? "Wallet Connected" : "Connect Wallet"}
            </Link>
          </h2>
          <div className="apps-card">
            <div className="mb-8 mt-4 config-grid-box">
              {/* <div className="grid gap-6 mb-4 md:grid-cols-2 xl:grid-cols-2">
                <div className="custom-input">
                  <label className="block">
                    <span>Give Minter Access</span>
                    <input
                      className="block form-input"
                      placeholder="Enter Minter Address"
                      type="text"
                      name="minter"
                      value={minterAddress}
                      onChange={(event) => setMinterAddress(event.target.value)}
                    />
                  </label>
                </div>
                <div className="app-card-buttons btn-align">
                  <button
                    className="content-button status-button"
                    onClick={grandMintAccess}
                    disabled={buttonContent != "" ? true : false}
                  >
                    {buttonContent != "" ? buttonContent : "Submit"}
                  </button>
                </div>
              </div>

              <div className="grid gap-6 mb-4 md:grid-cols-2 xl:grid-cols-2">
                <div className="custom-input">
                  <label className="block">
                    <span>Give Burner Access</span>
                    <input
                      className="block form-input"
                      placeholder="Enter Burner Address"
                      type="text"
                      name="burner"
                      value={burnerAddress}
                      onChange={(event) => setBurnerAddress(event.target.value)}
                    />
                  </label>
                </div>
                <div className="app-card-buttons btn-align">
                  <button
                    className="content-button status-button"
                    onClick={grandBurnAccess}
                    disabled={buttonContent != "" ? true : false}
                  >
                    {buttonContent != "" ? buttonContent : "Submit"}
                  </button>
                </div>
              </div>

              <div className="grid gap-6 mb-4 md:grid-cols-2 xl:grid-cols-2">
                <div className="custom-input">
                  <label className="block">
                    <span>Revoke Burner Access</span>
                    <input
                      className="block form-input"
                      placeholder="Enter Burner Address"
                      type="text"
                      name="revokeBurner"
                      value={revokeBurnerAddress}
                      onChange={(event) =>
                        setRevokeBurnerAddress(event.target.value)
                      }
                    />
                  </label>
                </div>
                <div className="app-card-buttons btn-align">
                  <button
                    className="content-button status-button"
                    onClick={revokeBurnAccess}
                    disabled={buttonContent != "" ? true : false}
                  >
                    {buttonContent != "" ? buttonContent : "Submit"}
                  </button>
                </div>
              </div>

              <div className="grid gap-6 mb-4 md:grid-cols-2 xl:grid-cols-2">
                <div className="custom-input">
                  <label className="block">
                    <span>Revoke Minter Access</span>
                    <input
                      className="block form-input"
                      placeholder="Enter Minter Address"
                      type="text"
                      name="revokeMinter"
                      value={revokeMinterAddress}
                      onChange={(event) =>
                        setRevokeMinterAddress(event.target.value)
                      }
                    />
                  </label>
                </div>
                <div className="app-card-buttons btn-align">
                  <button
                    className="content-button status-button"
                    onClick={revokeMintAccess}
                    disabled={buttonContent != "" ? true : false}
                  >
                    {buttonContent != "" ? buttonContent : "Submit"}
                  </button>
                </div>
              </div> */}

              <div className="grid gap-6 mb-4 md:grid-cols-2 xl:grid-cols-2">
                <div className="custom-input">
                  <label className="block">
                    <span>Set Release Limit</span>
                    <input
                      className="block form-input"
                      placeholder="Enter release limit"
                      type="text"
                      name="releaseLimit"
                      value={relLimit}
                      onChange={(event) =>
                        setRelLimit(event.target.value)
                      }
                    />
                  </label>
                </div>
                <div className="app-card-buttons btn-align">
                  <button
                    className="content-button status-button"
                    onClick={setReleaseLimit}
                    disabled={buttonContent != "" ? true : false}
                  >
                    {buttonContent != "" ? buttonContent : "Submit"}
                  </button>
                </div>
              </div>

              <div className="grid gap-6 mb-4 md:grid-cols-2 xl:grid-cols-2">
                <div className="custom-input">
                  <label className="block">
                    <span>Minting Fee</span>
                    <input
                      className="block form-input"
                      placeholder="Enter release limit"
                      type="text"
                      name="mintFee"
                      value={mintFee}
                      onChange={(event) =>
                        setMintFee(event.target.value)
                      }
                    />
                  </label>
                </div>
                <div className="app-card-buttons btn-align">
                  <button
                    className="content-button status-button"
                    onClick={setMintingFees}
                    disabled={buttonContent != "" ? true : false}
                  >
                    {buttonContent != "" ? buttonContent : "Submit"}
                  </button>
                </div>
              </div>

              <div className="grid gap-6 mb-4 md:grid-cols-2 xl:grid-cols-2">
                <div className="custom-input">
                  <label className="block">
                    <span>Set Public Minting</span>
                    {/* <input
                      className="block form-input"
                      placeholder="Enter release limit"
                      type="text"
                      name="publicMint"
                      value={publicMintStatus}
                      onChange={(event) =>
                        setPublicMintStatus(event.target.value)
                      }
                    /> */}
                    <div className="form-radio-btn-sec">
                      <div className="form-radio-label">
                        <input type="radio" name="mintStatus" id="flexRadioDefault1" checked={publicMintStatus == true ? true : false} onChange={() => setPublicMintStatus(true)} />
                        <label for="flexRadioDefault1">True</label>
                      </div>
                      <div className="form-radio-label">
                        <input type="radio" name="mintStatus" id="flexRadioDefault2" checked={publicMintStatus == true ? false : true} onChange={() => setPublicMintStatus(false)} />
                        <label for="flexRadioDefault2">False</label>
                      </div>
                    </div>
                  </label>
                </div>
                <div className="app-card-buttons btn-align">
                  <button
                    className="content-button status-button"
                    onClick={setPublicMintingStatus}
                    disabled={buttonContent != "" ? true : false}
                  >
                    {buttonContent != "" ? buttonContent : "Submit"}
                  </button>
                </div>
              </div>

              <div className="grid gap-6 mb-4 md:grid-cols-2 xl:grid-cols-2">
                <div className="custom-input">
                  <label className="block">
                    <span>Set Wallet retriction</span>
                    <input
                      className="block form-input"
                      placeholder="Enter release limit"
                      type="text"
                      name="walletCount"
                      value={walletCount}
                      onChange={(event) =>
                        setWalletCount(event.target.value)
                      }
                    />
                  </label>
                </div>
                <div className="custom-input">
                  <label className="block">
                    <span>Set Wallet Status</span>
                    {/* <input
                      className="block form-input"
                      placeholder="Enter release limit"
                      type="text"
                      name="walletStatus"
                      value={walletRestrictStatus}
                      onChange={(event) =>
                        setWalletRestrictStatus(event.target.value)
                      }
                    /> */}
                    <div className="form-radio-btn-sec">
                      <div className="form-radio-label">
                        <input type="radio" name="walletRes" id="flexRadioDefault3" checked={walletRestrictStatus == true ? true : false} onChange={() => setWalletRestrictStatus(true)} />
                        <label for="flexRadioDefault3">True</label>
                      </div>
                      <div className="form-radio-label">
                        <input type="radio" name="walletRes" id="flexRadioDefault4" checked={walletRestrictStatus == true ? false : true} onChange={() => setWalletRestrictStatus(false)} />
                        <label for="flexRadioDefault4">False</label>
                      </div>
                    </div>
                  </label>
                </div>
                <div className="app-card-buttons btn-align">
                  <button
                    className="content-button status-button"
                    onClick={setWalletRestriction}
                    disabled={buttonContent != "" ? true : false}
                  >
                    {buttonContent != "" ? buttonContent : "Submit"}
                  </button>
                </div>
              </div>


            </div>
          </div>
        </div>
      </main>
    </>
  );
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(null, mapDispatchToProps)(ConfigIndex);
